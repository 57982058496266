<template>
  <div>
    <div class="card card-custom card-nobody gutter-b">
      <div class="card-header">
        <div class="card-title div-center">
          <h3 class="card-label">Fiche utilisateur {{user.prenom}} {{user.nom}}</h3>
        </div>
        <div class="card-toolbar div-center">
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="save">
            <i class="icon-md fas fa-save"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <b-form>
          <b-form-group label="Nom:">
            <b-form-input v-model="user.nom" type="text" required placeholder="Entrez le nom"></b-form-input>
          </b-form-group>
          <b-form-group label="Prenom:">
            <b-form-input v-model="user.prenom" type="text" required placeholder="Entrez le prenom"></b-form-input>
          </b-form-group>
          <b-form-group label="Email:">
            <b-form-input v-model="user.email" type="text" required placeholder="Entrez l'email"></b-form-input>
          </b-form-group>
          <b-form-group label="Mot de passe:">
            <b-form-input v-model="user.password" type="text" required placeholder="Entrez le mot de passe"></b-form-input>
          </b-form-group>
          <div class="form-group">
            <label>Role:</label>
            <select class="form-control" v-model="user.role_id">
              <option v-for="(r,i) in params.role" :value="r.id" :key="i">{{r.titre.fr}}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Statut:</label>
            <select class="form-control" v-model="user.status">
              <option v-for="(s,i) in params.status" :value="s.id" :key="i">{{s.titre}}</option>
            </select>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      user:{
        "id": null,
        "nom": null,
        "prenom": null,
        "email": null,
        "telephone": null,
        "password": null,
        "role_id": 1,
        "langue_id": 1,
        "status": 1,
        "date_create": null,
        "date_update": null
      },
      params:{
        role:[],
        status:[]
      }
    }
  },
  components: {
  },
  watch: {
  },
  methods: {
    save() {

      this.$store.back.ajax('/user/create', this.user, (res) => {
        if(res.status === true){
          if(this.user.id === null){
            this.$router.push({path:'/user/detail/'+res.data.id});
            setTimeout(() => {location.reload()},500);
          }else{
            this.user = res.data;
            this.$bvToast.toast(`Vos modification sont sauvegarde !`, {
              title: 'Sauvegarder !',
              variant: 'success',
              solid: true
            });
          }
        }
      });
    }
  },
  computed: {

  },
  beforeMount(){
    if(this.$route.params.id != undefined){
      this.$store.back.ajax(`/user/${this.$route.params.id}`, null, (res) => {
        if(res.status === true){
          this.user = res.data;
        }
      });
    }
    let params = {
      modules: ["UserRole"]
    };
    this.$store.back.ajax('/magic/all', params, (res) => {
      if(res.status === true){
        this.params.role = res.data['UserRole'];
        this.params.status = res.data['status'];
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
  }
};
</script>
